.form_item {
    margin-bottom: 0 !important;
    max-width: 100% !important;
}

.drop_down {
    :global .rc-virtual-list-scrollbar {
        display: block !important;
    }
    :global .rc-virtual-list-scrollbar-thumb {
        border-radius: 0 !important;
    }
}
