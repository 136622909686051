.expandAll {
    position: absolute;
    top: 3px;
    left: 2px;
    z-index: 10;
}

.title {
    padding-left: 16px;
}
