.ant-tooltip .ant-tooltip-inner {
    background-color: #fff !important;
    color: #000 !important;
    font-size: 12px;
}
.ant-tooltip tr {
    border-bottom: 1px solid #dee2e6;
}
.ant-tooltip .ant-tooltip-arrow-content {
    background-color: #fff !important;
}

.ant-table-cell {
    white-space: pre-wrap !important;
}

.rh-table .react-dadata__suggestion {
    font-size: 15px !important;
    padding: 7px 10px !important;
    cursor: pointer !important;
    box-sizing: border-box !important;
    width: 100% !important;
    display: block !important;
    background: none !important;
    border: none !important;
    text-align: left !important;
    border: none !important;
    border-radius: none !important;
    color: black !important;
}
.rh-table .react-dadata__suggestion * {
    color: black !important;
}
.rh-table .react-dadata__suggestion:hover {
    background-color: rgba(0, 124, 214, 0.1) !important;
}
.ant-message {
    z-index: 1101 !important;
}
