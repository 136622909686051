.label_with_edit {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
}

.custom_color_cell {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
}

.comment {
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    height: 100%;
    max-width: 400px;

    &_empty {
        width: 400px;
    }
    &_text {
        font-size: 12px;
    }

    &_author {
        overflow: visible !important;
        margin-right: 5px;
        font-size: 12px;
    }
}

.text_ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tag {
    max-width: 200px !important;
    overflow: hidden;
    &_new {
        background: #fff;
        border-style: dashed;
    }
}

.balance {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-right: 5px;
}

.select {
    font-size: 12px !important;
}

.tel_number {
    cursor: pointer;
}

.close_circle {
    color: red !important;
    margin-right: 5px;
}
