.img {
    height: 520px !important;

    &_new {
        border: 5px solid #ff0000;
    }
}
.viewer {
    :global .react-viewer-toolbar {
        margin-bottom: 54px !important;
    }
}
