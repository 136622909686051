.main {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.85);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    z-index: 1500;
    transition: 1.5s;
}

.container {
    width: fit-content;
    padding: 10px 25px;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
}
