.ExpensesTable td {
    padding: 0 5px !important;
    white-space: nowrap !important;
}

.tag {
    margin: 0;
}

.actions {
    min-width: 186px;
    display: flex;
    flex-wrap: nowrap;
}

.actions > button {
    margin-right: 0.3rem;
}

.actions > button:last-child {
    margin-right: 0;
}

.actions:last-child {
    margin-right: 0;
}

.amount {
    padding: 0 3px;
    text-align: center;
}

.vendor {
    padding: 0 !important;
    & > span {
        text-align: left;
        white-space: break-spaces;
        max-width: 210px;
        flex-grow: 1;
    }
}

.client {
    white-space: pre-wrap;
    max-width: max-content;
    flex-grow: 1;
}

.comment {
    max-width: 310px;
    width: max-content;
    min-width: 150px;
    white-space: pre-wrap;
    margin-bottom: 0;
    flex-grow: 4;
}

.limitedWidth {
    line-height: 16px;
    display: table-caption;
    word-break: keep-all;
    overflow-wrap: initial;
}
