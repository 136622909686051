.table_container {
    & .ant-table-container > div:last-child {
        height: 300px !important;
    }

    & > div > div > div > div > div:nth-child(2) > div > div > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    :global .ant-table-header {
        background: #fafafa;
    }

    :global .ant-table-header table > thead > tr:first-child th:last-child {
        border-right: 0px !important;
    }

    :global .virtual-table-cell-last {
        box-shadow: 1px 0 0 0 #ffffff, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0,
            1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset !important;
    }
}

.reqistration_not_ok_color {
    background: #fee !important;

    :global .ant-table-cell-row-hover {
        background: #fee !important;
    }
}
