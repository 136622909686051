.row {
    margin-bottom: 25px;
    border: 1px solid rgb(213, 213, 213);
    flex-direction: column;
    padding: 8px;
    max-width: min-content;
}

.card {
    width: max-content;
    :global(.ant-card-cover) {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
    }

    :global(.ant-popover) {
        z-index: 9999;
    }
}

.container {
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap !important;
}
