.card {
    width: 300px;
    height: 100%;

    :global(.ant-card-cover) {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
    }

    :global(.ant-popover) {
        z-index: 9999;
    }
}
.cards_container {
    display: inline-flex;
    flex-direction: column;
}

.title {
    display: flex;
    margin-top: 50px;
}

.link {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    color: #999 !important;
    margin: 0 0 0.5em 0.2em;

    &:hover {
        color: #c00 !important;
    }
}
