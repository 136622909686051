.DadataAddressForAntForm input {
    border-radius: 2px;
    font-size: 14px;
    height: 32px;
}

.DadataAddressForAntForm input:focus {
    border-color: #40a9ff;
    border-right-width: 1px;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
