.main {
    display: flex;
}
.input {
    width: 70% !important;

    &::placeholder {
        font-size: 13.5px;
    }
}
