.main {
    display: flex;
    flex-direction: column;
    width: 100vw;
}
.sort_for_call_tooltip {
    :global .ant-tooltip-content {
        :global .ant-tooltip-inner {
            white-space: pre-wrap !important;
            padding: 0px 8px 6px 8px;
        }
    }
}

.form {
    &_item {
        margin-bottom: 7px !important;
    }
}
