$grid-breakpoints: (
                           xs: 0,
                           sm: 576px,
                           md: 768px,
                           lg: 992px,
                           xl: 1400px
                   );
$container-max-widths: (
                               sm: 540px,
                               md: 720px,
                               lg: 1360px,
                               xl: 1640px
                       );
.bg-danger {
  background: lighten(red, 30%);
}
.cursor-pointer{
  cursor: pointer;
}
.custom-select{
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}