.leaflet-tooltip {
    background: none;
    box-shadow: none;
    border: none;
    padding: 0;
    &-left,
    &-right {
        &::before {
            border: none !important;
        }
    }
}
