.tooltip {
    &_blue {
        opacity: 0.6 !important;
        padding: 1px;
        border-radius: 1px;
        border: 1px solid #55aafffa;
    }

    &_red {
        opacity: 0.6 !important;
        padding: 1px;
        border-radius: 1px;
        border: 1px solid #e44444fa;
    }

    &_not_active {
        opacity: 0.25 !important;
    }

    &_blue_active {
        opacity: 1 !important;
        padding: 3px;
        border-radius: 1px;
        border: 3px solid #55aafffa;
        z-index: 101;
    }

    &_red_active {
        opacity: 1 !important;
        padding: 3px;
        border-radius: 1px;
        border: 3px solid #e44444fa;
        z-index: 100;
    }

    &_container {
        cursor: pointer;
        background: #fff;
        padding: 6px;
        border-radius: 3px;
        width: 100%;
        margin: 0;
        font-size: 10px;
        text-align: center;
    }
}
