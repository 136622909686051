.comments {
    height: 220px;
    overflow-x: hidden;
    overflow-y: scroll;

    & > div {
        padding: 6px 0;
    }

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--dark);
    }
}

.comments_data {
    padding: 0 10px;
}
