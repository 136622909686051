.item {
    display: flex;
    align-items: center;
}
.form {
    justify-content: space-between;
}
.zone {
    margin: 0 5px 0 10px !important;
}

.title {
    padding-top: 6px;
}
.time {
    padding-top: 6px;
    margin-left: -12px;
}
