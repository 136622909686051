.worker_action_container {
    display: flex;
}

.table {
    :global .ant-table {
        overflow-x: auto !important;
    }

    :global .ant-table-cell {
        font-size: 12px;
        padding: 8px;
    }
}

.form {
    width: 100%;
    :global .ant-form-item {
        width: 80%;
    }
}

.submit_button {
    display: flex;
    align-items: center;
    width: 10% !important;
}

.worker_fullName {
    display: flex;
    flex-direction: column;
}

.worker_action {
    height: 55px;
    display: flex;
    flex-direction: column;

    &_unknown_timepoint {
        background: #ff7a7a !important;

        &:hover {
            background: #ff7a7a !important;
        }
    }

    &_tag {
        &_first {
            padding: 3px;
            background: #52c41a;
            color: var(--light);
            font-size: 11px;
            border-radius: 5px;
            width: max-content;
            height: max-content;
        }
    }
}

.dublicate {
    background: var(--red) !important;
}
