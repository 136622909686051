.modalTableDefault {
    margin-bottom: 35px;

    :global .ant-table-cell {
        font-size: 12px;
        padding: 8px;
    }
}

.form {
    width: 100%;
    :global .ant-form-item {
        width: 80%;
    }
}

.submit_button {
    display: flex;
    align-items: center;
    width: 10% !important;
}
.tag {
    &_first {
        padding: 3px;
        background: var(--green);
        max-width: max-content;
        color: var(--light);
        font-size: 12;
    }
}

.worker_fullName {
    display: flex;
    flex-direction: column;
}
.worker_action {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &_unknown_timepoint {
        background: #ff7a7a;

        &:hover {
            background: #ff7a7a !important;
        }
    }
}
