.calendar-table-row-light {
    padding: 0px !important;
    &>td {
        padding: 0px !important;
        white-space: nowrap !important;
        border-left: 2px solid grey;
    } 
}

.calendar-table-row-dark {
    padding: 0px !important;
    &>td {
        padding: 0px !important;
        white-space: nowrap !important;
        border-left: 2px solid grey;
    } 
}

.calendar-table-column {
    padding: 0px !important;
    white-space: nowrap !important;
    border-left: 2px solid grey;     
}
