.overdued {
    background: #fa198b95;
}
.row {
    overflow-y: visible;
    height: 21px;
}

.delivery_table_row_new {
    background: #ffffff;
}

.delivery_table_row_cancelled {
    background: #ffcccc;
}

.delivery_table_row_failed {
    background: #ffaaaa;
}

.delivery_table_row_driver_callback {
    background: #ffffaa;
}

.delivery_table_row_no_response {
    background: #ff8000aa;
}

.delivery_table_row_cancelled_with_payment {
    background: #ccffcc;
}

.delivery_table_row_timepoint_confirmed {
    background: #49c6fbaa;
}

.delivery_table_row_partly_confirmed {
    background: #7a57c770;
}

.delivery_table_row_partly_arrival_submitted {
    background: #cdcaf6aa;
}

.delivery_table_row_partly_arrived {
    background: #d9ddf2aa;
}

.delivery_table_row_partly_photo_attached {
    background: #d9eef2aa;
}

.delivery_table_row_photo_attached {
    background: #c6ecdfaa;
}

.delivery_table_row_finished {
    background: #eeffee;
}

.timepoint_ok {
    background: #ff2400aa;
}
