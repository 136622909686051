.table {
    :global .ant-table-content {
        font-size: 10px;
    }

    :global .ant-btn {
        font-size: 10px;
    }

    :global .ant-table-cell {
        padding: 0;
        margin: 0;
        background: #fafafa !important;
    }

    :global .virtual-grid {
        font-size: 12px;
    }

    :global .virtual-table-cell {
        padding: 4px !important;
        display: flex;
        align-items: center;
        box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0,
            1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;

        > div > div {
            padding-left: 0 !important;
        }
    }
    :global .ant-table table {
        width: min-content !important;
    }

    :global .ant-table-header {
        overflow: visible !important;
        position: sticky;
        top: 0;
        z-index: 100;
    }

    .newTag {
        background: #fff;
        border-style: dashed;
    }
}

.grid {
    overflow: hidden !important;
}
