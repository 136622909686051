.rh-image-checkbox {
  position:          absolute;
  top:               5px;
  right:             7px;
  bottom:            0;
  -ms-transform:     scale(1.5); /* IE */
  -moz-transform:    scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform:      scale(1.5); /* Opera */
}
.rh-image-big {
  height: 100vh;
}
.rh-image-button {
  position:         absolute;
  top:              5px;
  left:             5px;
  padding:          2px;
  background-color: $white;
}
.rh-image-rotate {
  position: absolute;
  top:      5px;
  left:     5px;
}
.rh-photo-gallery {
  .rh-image-container {
    width:    120px;
    height:   120px;
    overflow: hidden;
    image {
      width:      100%;
      height:     100%;
      object-fit: cover;
    }
  }
}
.rh-photo-load {
  #galery {
    > div {
      width:    120px;
      height:   120px;
      overflow: hidden;
      .img-thumbnail {
        width:      100%;
        height:     100%;
        object-fit: cover;
      }
    }
    .rh-card-img-overlay {
      @extend .card-img-overlay;
      color:            $white;
      font-size:        4em;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  #blueimp-gallery {
    a {
      color: $white !important;
    }
  }
}
.rh-vertical-form {
  .form-group {
    margin-bottom: 0;
    .form-control {
      padding-top:    0.25rem;
      padding-bottom: 0.25rem;
      font-size:      0.8rem;
    }
    .col-form-label {
      white-space: nowrap;
      overflow:    hidden;
      @include media-breakpoint-down(lg) {
        padding-top:    calc(1px + 0.33rem);
        padding-bottom: calc(1px + 0.33rem);
      }

    }
    select.form-control:not([size]):not([multiple]) {
      height: calc(1.6rem + 2px);
    }
  }
}
#workerForm {
  .nav-link {
    @include media-breakpoint-down(lg) {
      padding-top:    0.25rem;
      padding-bottom: 0.25rem;
    }
  }
  .page-header {
    font-size: 1rem;
    @include media-breakpoint-down(lg) {
      font-size: 0.8rem;
    }
    .header-link {
      font-size: 90%;
    }
    .header-text {
      font-size: 100%;
    }
  }
}
