.delivery_table {
    display: flex;
    table-layout: fixed;
    position: relative;
    /* overflow-x: scroll; */
    padding-bottom: 20px;
}

.delivery_table::-webkit-scrollbar {
    display: none;
    width: 0;
}

.delivery_table table:first-of-type {
    position: sticky;
    left: 0;
    z-index: 3;
    background: white;
}

.delivery_table .photos-badge .ant-badge-count-sm {
    font-size: 10px;
}

.delivery_table th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;

    background: white;
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.delivery_table.rh-table tr td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    padding: 0 4px !important;
}

.delivery_table tbody td input {
    height: 16px;
    outline: none;
}

.delivery_table tbody td.editable {
    overflow: visible;
}

.delivery_table tbody td.editable input {
    width: 90px;
}

.delivery_table tbody td.editable .react-dadata__input {
    width: 100%;
    height: auto;
}

.delivery_table.rh-table tr td.delivery_table_cell_pk {
    max-width: none;
}
.delivery_table_cell_pk,
.delivery_table_cell_route {
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    height: 21px;
}
.delivery_table_cell_metro_line {
    padding: 0;
}
.delivery_table_cell_code {
    height: 21px;
}
.delivery_table_cell_timesheet_photo {
    max-height: 21px;
}
.delivery_table_cell_timesheet_photo .ant-upload {
    line-height: 0;
    position: relative;
}

.tooltip_container {
    position: absolute;
    display: inline-block;
}

.delivery_table_cell .tooltip_text {
    display: none;
    white-space: nowrap;
    overflow: visible;

    background-color: #fff;
    color: #000;
    text-align: left;
    border-radius: 2px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: 50%;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.1s;
}

/* override ant's default */
.ant-tooltip {
    max-width: 1000px !important;
}

.ant-tooltip .ant-tooltip-inner {
    background-color: #fff !important;
    color: #000 !important;
}

.ant-badge-count {
    min-width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 8px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.delivery_table_cell:hover .tooltip_text {
    display: block;
    opacity: 1;
}

.delivery_table_column {
    background-color: rgba(0, 0, 0, 0.075);
}
