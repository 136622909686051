.card {
    width: max-content;
    :global(.ant-card-cover) {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
    }

    :global(.ant-popover) {
        z-index: 9999;
    }
}
