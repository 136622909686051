.main {
    position: absolute;
    display: flex;
    flex-flow: column;
    padding: 5px 5px;
    top: 50px;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
}
.title {
    margin: 0;
    color: #ffffff;
}

.subtitle {
    margin: 0;
    color: #ffffff;
    font-weight: bold;
}
