.time {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    height: 30px;
    border: 1px lightgray solid;
}

.footer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
}
